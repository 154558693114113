import React from "react"
import Layout from "../components/shared/Layout/layout"
import SEO from "../components/seo"
import Container from "../components/shared/Layout/components/Container/container"
import styled from "styled-components"
import { Section } from "../components/shared/Layout/components/Section/Section"
import Vehicles from "../components/VehiclesPark/Vehicles"
import { PageHeader } from "../components/shared/PageHeader/PageHeader"

const PartnersSection = styled(Section)``

const Intro = styled(PageHeader)`
  margin-bottom: 50px;
`
const Content = styled.div``
const IntroDescription = styled.div`
  margin: 30px 0;
  max-width: 800px;
`
const SecondPage = () => (
  <Layout fluid={false}>
    <SEO title="Náš vozový park" description="Vozový park autoškolz HRON." />
    <PartnersSection>
      <Container fluid={false}>
        <PageHeader header="Náš vozový park" subheader="Vozidlá" />
        <IntroDescription
          data-sal-duration="650"
          data-sal="fade"
          data-sal-easing="ease-in"
          data-sal-delay="200"
        >
          <p>
            Praktický výcvik prebieha na nových vozidlách VW Golf VII a VW Golf
            VII Sportsvan na ktorom si môžete vyskúšať jazdu s adaptívnym
            tempomatom ACC s navigáciou, vyskúšať cúvanie s cúvacov kamerou a
            počas jazdy sa môžete nechať masírovať masážným sedadlom vodiča a
            mnoho ďalších zaujímavých funkcií vozidla.
          </p>
        </IntroDescription>
        <Content>
          <Vehicles />
        </Content>
      </Container>
    </PartnersSection>
  </Layout>
)

export default SecondPage
