import { useMediaQuery } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { FluidObject } from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { muiTheme } from "../../lib/theme"

export interface FluidImage {
  childImageSharp: {
    fluid: FluidObject
  }
}

interface Props {
  name: string
  description?: string
  image: FluidImage
  imgSide: "left" | "right"
}
const Description = styled.div`
  padding: 30px 50px;
  padding-left: 0;
`

const ImageWrapp = styled.div`
  height: 400px;
  display: flex;

  ${muiTheme.breakpoints.down("sm")} {
    height: auto;
  }
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.19);
`

const VehicleInner = styled(Grid)`
  padding-bottom: 50px;
`
const Vehicle: React.FC<Props> = (props) => {
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("sm"))

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-24">
      <div className={`image ${!isMobile && props.imgSide === "left" ? "order-first" : "order-last"}`}>
        <div className=" h-auto lg:h-96 shadow-xl">
          <GatsbyImage className="h-full" image={getImage(props.image.localFile)} />
        </div>
      </div>
      <div className="left flex flex-col justify-center ">
        <span className="upTitle">Vozidlo</span>
        <h3>{props.name}</h3>
        {props.description?.length > 2 && (
          <Description>
            <p>{props.description}</p>
          </Description>
        )}
      </div>
    </div>
  )
}

export default Vehicle
