import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Section } from "../shared/Layout/components/Section/Section"
import Vehicle from "./Vehicle"

const VehiclesSection = styled(Section)``
const Vehicles: React.FC = () => {
  const { allStrapiVehicleCollection } = useStaticQuery(graphql`
    {
      allStrapiVehicleCollection {
        edges {
          node {
            name
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 80, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `)

  const Vehicles = allStrapiVehicleCollection.edges

  return (
    <VehiclesSection>
      <div className="space-y-16 overflow-visible">
        {Vehicles.map((vehicle, index) => {
          console.log(vehicle)
          return (
            <Vehicle
              key={index}
              name={vehicle.node.name}
              image={vehicle.node.image}
              description={vehicle.node.description}
              imgSide={index % 2 === 0 ? "left" : "right"}
            />
          )
        })}
      </div>
    </VehiclesSection>
  )
}

export default Vehicles
